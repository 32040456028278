<template>
  <div class="refund-detail">
    <el-form
      class="passenger-input-info"
      label-position="top"
    >
      <div>
        <span class="item-title">退票状态</span>
        <span class="item-value">{{thirdStatus ? thirdStatusDes : '退票审核中'}}</span>
      </div>
      <div>
        <span class="item-title">{{ thirdStatus ? '实际金额' : '预计退还'}}</span>
        <span class="item-value order-price">¥{{countPrice.anticipate}}</span>
        <span
          style="cursor:pointer"
          @click="show"
        >查看明细</span>
        <img
          @click="show"
          v-if="!detailVisible"
          class="order-drop"
          src="../../assets/drop.png"
          alt=""
        >
        <img
          @click="show"
          v-else
          style="transform: rotate(180deg)"
          class="order-drop"
          src="../../assets/drop.png"
          alt=""
        >
        <div
          class="detail-content fee-panel-group"
          v-if="detailVisible"
        >
          <ul class="list">
            <li>
              <span>预计退还</span>
              <span>¥{{countPrice.anticipate}}</span>
            </li>
            <li>
              <span>实际支付</span>
              <span>¥{{countPrice.orderAmount}}</span>
            </li>
            <!-- <li>
                            <span>需扣款项</span>
                            <span>¥{{detail.applyRefundFee}}</span>
                        </li> -->
            <li>
              <span>退票费</span>
              <span>-¥{{countPrice.needRefund}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="detail-progress">
        <div class="fl">
          <div class="flt"><img
              src="../../assets/icon-ac.png"
              style="width:32px;height:32px;"
            />
            <span class="bg">提交申请</span>
          </div>
          <div class="flt"><img
              src="../../assets/icon-ac.png"
              style="width:32px;height:32px;"
            />
            <span class="bg">退票审核中</span>
          </div>
          <div
            v-if="thirdStatus && thirdStatusDes =='退票成功'"
            class="flt"
          ><img
              src="../../assets/icon-ac.png"
              style="width:32px;height:32px;"
            />
            <span class="bg">退票成功</span>
          </div>
          <div
            v-else-if="thirdStatus && thirdStatusDes == '退票被驳回'"
            class="flt"
          ><img
              src="../../assets/icon-noac.png"
              style="width:32px;height:32px;"
            />
            <span class="rg">退票被驳回</span>
          </div>
          <div
            v-else-if="thirdStatus && thirdStatusDes == '退款失败'"
            class="flt"
          ><img
              src="../../assets/icon-noac.png"
              style="width:32px;height:32px;"
            />
            <span class="rg">退款失败</span>
          </div>
          <div
            v-else
            class="flt glt"
          ><img
              src="../../assets/icon-noac.png"
              style="width:32px;height:32px;"
            />
            <span class="gg gm">退票成功</span>
          </div>

        </div>
        <div class="fr">
          <div class="frd"><span class="dt">{{ detail.createTime }}</span>
          </div>
          <div class="frd"><span class="dt">{{ detail.createTime }}</span>
          </div>
          <div
            class="frd"
            v-if="thirdStatus"
          ><span class="dt">{{ detail.updateTime }}</span>
          </div>
        </div>
      </div>
      <div class="flex">
        <span class="item-title">行程信息</span>
        <div class="item-value">
          <div
            v-for="(dd, idx) in detail.refundDetailList"
            :key="idx"
          >
            <div class="uni-form-item refund-check-its">
              {{formatDate(dd.psi.segment.depDatetime,"MM月DD日")}}
              {{dd.psi.segment.depCityName}}-{{dd.psi.segment.arrCityName}}
              {{formatDate(dd.psi.segment.depDatetime,"HH:mm")}}起飞
            </div>
            <div class="uni-form-item refund-check-itp">
              {{ dd.psi.passenger.name}} {{ dd.psi.ticket.ticketNo.replace("-","")}}
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <span class="item-title">退票原因</span>
        <div class="item-value">
          <div class="uni-form-item">
            {{detail.refundType == "UNCONSTRAINT" ? "自愿" : "非自愿" }}
          </div>
          <div class="uni-form-item">
            {{detail.refundReason}}
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: ["detail"],
  data() {
    return {
      detailVisible: false,
      thirdStatus: false,
      thirdStatusDes: "",
      countPrice: {
        anticipate: "",
        needRefund: "",
        factRefund: "",
        orderAmount: ""
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.transRefundStatus(this.detail.refundStatus);
      this.countPrice.anticipate = this.thirdStatus
        ? this.detail.actualRefundAmount
        : this.detail.applyRefundAmount;
      this.countPrice.factRefund = this.thirdStatus
        ? this.detail.actualRefundFee
        : this.detail.applyRefundFee;
      this.countPrice.needRefund = this.thirdStatus
        ? this.detail.actualRefundFee
        : this.detail.applyRefundFee;
      this.countPrice.orderAmount = (
        Number(Math.abs(this.detail.applyRefundAmount)) +
        Number(Math.abs(this.detail.applyRefundFee))
      ).toFixed(2);
    },
    show() {
      this.detailVisible = !this.detailVisible;
    },
    formatDate(timestamp, format) {
      return moment(timestamp).format(format);
    },
    transRefundStatus(status) {
      if (status == "REFUND_PAID") {
        this.thirdStatus = true;
        this.thirdStatusDes = "退票成功";
      } else if (status == "AUDIT_ONE_REJECT" || status == "AUDIT_TWO_REJECT") {
        this.thirdStatus = true;
        this.thirdStatusDes = "退票被驳回";
      } else if (status == "AUDIT_FAIL") {
        this.thirdStatus = true;
        this.thirdStatusDes = "退款失败";
      } else {
        this.thirdStatus = false;
        this.thirdStatusDes = "退票成功";
      }
      return this.thirdStatusDes;
    }
  }
};
</script>
<style lang="less" scoped>
.refund-detail {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  &:last-child {
    border: 0;
  }
}
.flex {
  display: flex;
}
.item-title {
  color: #929a9f;
  font-size: 14px;
  width: 70px;
  display: inline-block;
}
.item-value {
  color: #515457;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}
.order-drop {
  width: 12px;
  height: 10px;
  margin-left: 10px;
}
.detail-content {
  position: absolute;
  width: 250px;
  /* height: 200px; */
  border-radius: 5px;
  border: 1px solid #ededed;
  background: #ffffff;
  z-index: 10000;
  padding: 10px;
}
.fee-panel-group ul li {
  height: 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #4d4e4e;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-price {
  font-size: 24px;
  color: #d70039;
  margin: 0 10px;
}
.detail-progress {
  display: flex;
  background-color: #ffffff;

  .fr,
  .fl {
    flex: 1;
    padding: 20px 20px;
  }

  .fl {
    text-align: left;
    color: #333333;

    .flt {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 20px;

      img {
        margin-right: 20px;
      }

      .bg {
        color: #03a9f4;
      }

      .rg {
        color: #d6003a;
      }

      .gg {
        color: #999999;
      }

      span {
        position: relative;

        &:after {
          position: absolute;
          content: "";
          height: 1px;
          width: 400px;
          left: 170px;
          top: 16px;
          border-top: 1px dotted #999;
        }
      }

      .gm::after {
        display: none;
      }

      &::before {
        position: absolute;
        content: "";
        height: 22px;
        width: 1px;
        left: 16px;
        top: -21px;
        background: #03a9f4;
      }

      &:first-child::before {
        display: none;
        content: "";
      }
    }
    .glt::before {
      background: #e0d6d6;
    }
  }

  .fr {
    text-align: right;
    color: #515457;
    .frd {
      margin-bottom: 20px;
    }
  }
}
</style>


<template>
    <div class="order-detail-wrapper" style="margin: 20px auto;">
        <div class="container" v-loading="loading">
            <el-row gutter="20">
                <el-col span="5">
                    <memberleft :activeIndex="leftPannelSelected" />
                </el-col>
                <el-col span="19">
                    <div v-if="!limit">
                        <div class="passenger">
                            <div class="info-panel" v-if="order.orderStatus != undefined">
                                <div class="info-panel">
                                    <div class="booked-ticket">
                                        <div class="booked-title">
                                            <div class="title">订单信息</div>
                                        </div>
                                        <div class="booked-ticket-body">
                                            <div class="passenger-info no-padding">
                                                <div class="book-panel" v-if="order.orderStatus == 'IW'">
                                                    <div @click="gotoRefund(orderNo)">退票</div>
                                                    <div @click="endorse(orderNo)">改期</div>
                                                </div>
                                                <div class="book-panel" v-if="order.orderStatus == 'WP'">
                                                    <div @click="endorse(orderNo)">支付</div>
                                                </div>
                                                <el-form class="passenger-input-info" style="margin-top:10px;" label-position="top">
                                                    <div>
                                                        <div>
                                                            <span class="item-title">订单状态</span>
                                                            <span class="item-value">{{ order.orderStatusDesc }} {{ order.refundList.length ? "-有退票" : "" }}</span>
                                                        </div>
                                                        <div>
                                                            <span class="item-title">订单号</span>
                                                            <span class="item-value">{{ order.orderNo }}</span>
                                                        </div>
                                                        <div>
                                                            <span class="item-title">预订日期</span>
                                                            <span class="item-value">{{ order.createTime | formatDate }}</span>
                                                        </div>
                                                        <div>
                                                            <span class="item-title">总金额</span>
                                                            <div class="item-value" style="display: inline-block">
                                                                <span class="order-price">¥{{ order.orderAmount }}</span>
                                                                <span style="cursor:pointer" @click="showDetail">查看明细</span>
                                                                <img @click="showDetail" v-if="!detailVisible" class="order-drop" src="../../assets/drop.png" alt="" />
                                                                <img @click="showDetail" v-else style="transform: rotate(180deg)" class="order-drop" src="../../assets/drop.png" alt="" />
                                                                <div class="detail-content fee-panel-group" v-if="detailVisible">
                                                                    <div v-for="fare in fareData" v-bind:key="fare.title">
                                                                        <ul class="list">
                                                                            <li>
                                                                                <span>{{ fare.title }}</span>
                                                                                <span>¥{{ fare.price }} * {{ fare.passCount }}</span>
                                                                            </li>
                                                                            <li>
                                                                                <span>机建+燃油</span>
                                                                                <span>¥{{ fare.tax }} * {{ fare.passCount }}</span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <ul class="list">
                                                                        <li>
                                                                            <span>航班保险</span>
                                                                            <span>¥0</span>
                                                                        </li>
                                                                        <li>
                                                                            <span>保险优惠减免</span>
                                                                            <span>¥0</span>
                                                                        </li>
                                                                        <li>
                                                                            <span>
                                                                                优惠减免
                                                                                <i class="iconfont icon-question"></i>
                                                                            </span>
                                                                            <span>¥0</span>
                                                                        </li>
                                                                        <li>
                                                                            <span>里程抵扣</span>
                                                                            <span>¥0</span>
                                                                        </li>
                                                                        <li>
                                                                            <span>邮寄费用</span>
                                                                            <span>¥0</span>
                                                                        </li>
                                                                    </ul>
                                                                    <div class="footer">
                                                                        <!-- <span>订单总金额</span> -->
                                                                        <span>¥{{ order.orderAmount }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="booked-ticket">
                                        <div class="booked-title">
                                            <div class="title">行程信息</div>
                                        </div>
                                        <div class="booked-ticket-body">
                                            <div class="passenger-info no-padding">
                                                <div class="form-title">
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                                <el-form class="passenger-input-info" style="margin-top:10px;" label-position="top">
                                                    <div>
                                                        <!-- <span class="item-title">航班信息</span> -->
                                                        <span class="item-value">
                                                            <fee-panel :orderNo="orderNo" :segments="order.segments" />
                                                        </span>
                                                    </div>
                                                </el-form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="booked-ticket">
                                        <div class="booked-title">
                                            <div class="title">乘机人信息</div>
                                        </div>
                                        <div class="booked-ticket-body">
                                            <div class="passenger-info no-padding">
                                                <el-form class="passenger-input-info" label-position="top">
                                                    <div style="display: flex;" v-for="(pass, idx) in order.passengers" v-bind:key="pass.certNo">
                                                        <div class="item-index">{{ idx + 1 }}</div>
                                                        <div class="order-pass-item">
                                                            <div>
                                                                <span class="item-title">{{ pass.type | formatType }}</span>
                                                                <span class="item-value">{{ pass.name }}</span>
                                                            </div>
                                                            <div>
                                                                <span class="item-title">{{ formatCertType(pass.certType) }}</span>
                                                                <span class="item-value">{{ pass.certNo }}</span>
                                                            </div>
                                                            <div>
                                                                <span class="item-title">手机号</span>
                                                                <span class="item-value">{{ order.contact.mobile | phoneStash }}</span>
                                                            </div>
                                                            <div v-if="pass.tickets[0].ticketNo">
                                                                <span class="item-title">票号</span>
                                                                <span class="item-value">
                                                                    {{ pass.tickets[0].ticketNo }}
                                                                    <span class="ticketStatus">{{ pass.tickets[0].ticketStatus | formatActualStatus }}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="booked-ticket">
                                        <div class="booked-title">
                                            <div class="title">联系人</div>
                                        </div>
                                        <div class="booked-ticket-body">
                                            <div class="passenger-info" style="padding: 8px 8px 8px 20px;">
                                                <el-form class="passenger-input-info" label-position="top">
                                                    <div>
                                                        <span class="item-title">手机号码</span>
                                                        <span class="item-value">{{ order.contact.mobile | phoneStash }}</span>
                                                    </div>
                                                </el-form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="booked-ticket" v-if="order.refundInfos">
                                        <div class="booked-title">
                                            <div class="title">退票信息</div>
                                        </div>
                                        <div class="booked-ticket-body">
                                            <div class="passenger-info" style="padding: 8px 8px 8px 20px;">
                                                <RefundDetail :detail="item" v-for="(item, idx) in order.refundInfos" :key="idx" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;color: #666;" v-else>
                        <img src="https://static.a6air.com/wxapp/icon_request_limit.png" style="width: 364px; height: 261px" />
                        <span style="line-height: 112px;">操作过于频繁，请稍后再试</span>
                    </div>
                </el-col>
            </el-row>
        </div>
        <RefundDialog @close="refundVisible = false" :visible="refundVisible" :orderNo="orderNo" :fareData="fareData" />
    </div>
</template>
<script>
import Vue from "vue";
import { orderDetail, getCertType } from "@/api/newsapi";
import StickySidebar from "sticky-sidebar";
import ProgressBar from "../../components/ProgressBar";
import FeePanel from "../../components/Book2FeePanel";
import PopoverContent from "../../components/PopoverContent";
import LuggagePopOver from "../../components/LuggagePopOver";
import moment from "moment";
import memberleft from "../../components/MemberLeft";
import RefundDialog from "../../components/Refund";
import RefundDetail from "./RefundDetail";
import wxCode from "@/assets/wxapp_code.jpg";

export default {
    data() {
        return {
            order: {},
            fareData: [],
            orderNo: "",
            showRefund: false,
            intervelTime: "",
            loading: true,
            flightUnits: [{ segmentList: [{ depDatetime: 1589937300000 }] }],
            flightUnit: { segmentList: [{ depDatetime: 1589937300000 }] },
            detailVisible: false,
            leftPannelSelected: "orderlist",
            refundVisible: false, // 退票
            wxCode,
            limit: false
        };
    },
    components: { FeePanel, memberleft, RefundDialog, RefundDetail },
    async mounted() {
        this.orderNo = this.$route.params.orderNo;
        await this._getCertType();
        await this.orderDetailHandler();
    },
    filters: {
        formatDate(timestamp) {
            return moment(timestamp).format("YYYY-MM-DD");
        },
        formatType(type) {
            let typeDesc = "";
            switch (type) {
                case "ADT":
                    typeDesc = "成人";
                    break;
                case "CHD":
                    typeDesc = "儿童";
                    break;
                case "INF":
                    typeDesc = "儿童";
                    break;
                default:
                    typeDesc = "";
                    break;
            }
            return typeDesc;
        },

        formatActualStatus(actualStatus) {
            var statusDesc = "";
            switch (actualStatus) {
                case "NEW":
                    statusDesc = "未出票";
                    break;
                case "OPENFORUSE":
                    // if(this.order.orderStatus=='IW'){
                    statusDesc = "已出票";
                    // }
                    // else{
                    //  statusDesc="";
                    // }
                    break;
                case "EXCHANGE":
                    statusDesc = "改期或升舱";
                    break;
                case "TVOID":
                    statusDesc = "已作废";
                    break;
                case "TREFUNDED":
                    statusDesc = "已退票";
                    break;
                case "CHECKEDIN":
                    statusDesc = "已办理值机";
                    break;
                case "LIFTORBOARED":
                    statusDesc = "客票已使用";
                    break;
                case "USEDORFLOWN":
                    statusDesc = "客票已使用";
                    break;
                case "SUSPENDED":
                    statusDesc = "已挂起";
                    break;
                case "EXCHANGED":
                    statusDesc = "已换开";
                    break;
                default:
                    statusDesc = "未出票";
                    break;
            }

            return statusDesc;
        },
        formatTime(timestamp) {
            return moment(timestamp).format("HH:mm");
        }
    },
    methods: {
        reloadPage() {
            window.location.reload();
            //this.window. reload();
        },
        payHandler() {
            console.log("支付检测");
            window.open("/route/pay?bank=alipay&orderNo=" + this.order.orderNo, "_blank");
        },
        gotoRefund(orderNO) {
            //   /order/refund/:orderNo
            this.$router.push({
                path: "/order/refund2/" + orderNO,
                query: { t: new Date().getTime() }
            });
            //this.refundVisible = true;
        },
        endorse() {
            const h = this.$createElement;
            this.$msgbox({
                title: "请扫码进入湖南航空小程序进行改期",
                message: h(
                    "div",
                    {
                        style: {
                            textAlign: "center"
                        }
                    },
                    [
                        h("img", {
                            attrs: {
                                src: this.wxCode
                            }
                        })
                    ]
                ),
                showConfirmButton: false
            });

            // this.$alert('如需改期，请拨打湖南航空客服热线<span style="color:#d70039">4008337777</span>为您服务。', {
            //     dangerouslyUseHTMLString: true,
            //     confirmButtonText: "我知道了"
            //   });
        },
        gotoMember() {
            //   /order/refund/:orderNo
            this.$router.push({
                path: "/member/orderlist",
                query: { t: new Date().getTime() }
            });
        },
        orderDetailHandler() {
            this.limit = false;
            var request = { t: this.orderNo };
            orderDetail(request).then(res => {
                this.loading = false;
                if (res.success) {
                    console.log(res);
                    this.order = res.data;
                    this.showOrder();
                } else {
                    if (res.errorCode === "A60511009101") {
                        this.limit = true;
                    }
                    this.flights = [];
                }
            });
        },
        showOrder() {
            var adtCount = 0,
                chdCount = 0,
                infCount = 0;
            for (var i = 0; i < this.order.passengers.length; i++) {
                if (this.order.passengers[i].type == "ADT") {
                    adtCount++;
                } else if (this.order.passengers[i].type == "CHD") {
                    chdCount++;
                } else if (this.order.passengers[i].type == "INF") {
                    infCount++;
                }

                console.log(this.order.passengers[i].tickets[0].actualStatus);
                if (this.order.passengers[i].tickets[0].actualStatus == "OPEN_FOR_USE" || this.order.passengers[i].tickets[0].ticketStatus == "OPENFORUSE") {
                    this.showRefund = true;
                    console.log(this.order.passengers[i].tickets[0].actualStatus);
                }
            }

            console.log(adtCount + " " + chdCount + " " + infCount);

            if (adtCount > 0) {
                var adtFare = {
                    title: "成人",
                    price: this.order.adultTotalPrice / adtCount,
                    passCount: adtCount,
                    tax: this.order.adultTotalTax / adtCount
                };
                this.fareData.push(adtFare);
            }

            if (chdCount > 0) {
                var chdFare = {
                    title: "儿童",
                    price: this.order.childTotalPrice / chdCount,
                    passCount: chdCount,
                    tax: this.order.childTotalTax / chdCount
                };
                this.fareData.push(chdFare);
            }

            if (infCount > 0) {
                var infFare = {
                    title: "婴儿",
                    price: this.order.infantTotalPrice / infCount,
                    passCount: infCount,
                    tax: this.order.infantTotalTax / infCount
                };
                this.fareData.push(infFare);
            }
            this.payIntevel();
        },
        formatDuring(mss) {
            var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = (mss % (1000 * 60)) / 1000;
            seconds = parseInt(seconds);
            return minutes + " 分 " + seconds + " 秒 ";
        },
        payIntevel() {
            var that = this;
            setInterval(function() {
                var remainMs = that.order.payTimeLimit - new Date().getTime();
                if (remainMs <= 0) {
                    return;
                }
                that.intervelTime = that.formatDuring(remainMs);
            }, 1000);
        },
        showDetail() {
            this.detailVisible = !this.detailVisible;
        },

        _getCertType() {
            return getCertType().then(res => {
                if (res.success) {
                    this.certTypeList = res.data;
                }
            });
        },

        formatCertType(certType) {
            let cert = (this.certTypeList || []).find(c => c.code == certType);
            return cert ? cert.text : "";
        }
    }
};
</script>
<style>
.next-step {
    width: 100%;
    height: 53px;
    border: 0;
    background-color: #d70039;
    color: #fff;
    font-size: 16px;
    margin: 42px 0 53px;
}
.next-step:active {
    background-color: #d70039;
}
.add-passenger-tips {
    font-size: 14px;
    color: #9d9d9d;
}
.add-passenger-tips span {
    color: orange;
}
.add-wrapper {
    display: flex;
    align-items: center;
}
.add-passenger {
    margin-right: 10px;
    background-color: #d70039;
    width: 163px;
    height: 43px;
    color: #fff;
    border: 0;
    cursor: pointer;
}
.add-passenger:active {
    background-color: #d70039;
    border-color: #d70039;
}
.save-often .el-checkbox__label {
    font-size: 12px;
    color: #bc8b0d !important;
}
.save-often .el-checkbox__input.is-checked .el-checkbox__inner,
.save-often .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #bc8b0d !important;
    border-color: #bc8b0d !important;
}
.save-often .el-checkbox__inner:hover {
    border-color: #bc8b0d !important;
}
.save-often .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #bc8b0d !important;
}
.icon-question {
    font-size: 13px;
}
.form-label {
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    font-weight: 700;
    color: #717070;
}
.passenger-input-info .el-form-item__label {
    padding-bottom: 0;
}
.passenger-input-info .el-form-item {
    margin-bottom: 10px;
}
.passenger-input-info .el-input__inner {
    border-radius: 0 !important;
}
.passenger .el-checkbox__input.is-checked .el-checkbox__inner,
.passenger .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #e34c4c;
    border-color: #e34c4c;
}
.el-checkbox__label {
    padding-left: 5px;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
    color: #e34c4c;
}
.el-checkbox__inner:hover {
    border-color: #e34c4c;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #e34c4c;
}
</style>
<style scoped lang="less">
.fee-panel-body .footer {
    padding: 0 26px;
    height: 60px;
    font-weight: 700;
    line-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fee-panel-body .footer span:nth-child(2) {
    font-size: 18px;
    color: #d70039;
}
h4 {
    height: 34px;
    font-size: 14px;
    font-weight: 700;
    line-height: 34px;
    color: #4d4e4e;
}
.noBefore {
    padding: 16px 0 0 10px;
    border-top: 1px solid #bcbcbd;
}
.noBefore::before {
    content: none;
    width: 0 !important;
}
.fee-panel-group ul li {
    height: 26px;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #4d4e4e;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* .fee-panel-group ul::before {
  position: absolute;
  top: 6px;
  left: 0;
  bottom: 6px;
  width: 2px;
  content: "";
  background-color: #d8d8d8;
} */
.fee-panel-group ul {
    position: relative;
    padding: 10px;
}
.fee-panel-group > div {
    margin-bottom: 10px;
}
.fee-panel-group {
    padding-left: 10px;
}
.fee-panel__inner {
    // border: 1px solid #eae7e7;
}
.fee-panel-content {
    padding: 16px;
    background-color: #fff;
}
.fee-panel-body {
    max-height: 485px;
    overflow-y: auto;
    background-color: #f8f8f8;
}
.fee-panel-header {
    height: 60px;
    line-height: 60px;
    color: #4d4e4e;
    font-size: 16px;
    font-weight: 700;
    background-color: #f8f8f8;
    border-bottom: 1px solid #ededed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
}
.tips-text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
}
.passenger-input-info .el-input,
.passenger-input-info .el-select {
    width: 287px;
}
.no-padding {
    padding: 0 16px !important;
}
.form-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form-title div:first-child {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    color: #b2b2b2;
    text-align: left;
}
.form-title div:nth-child(2) {
    margin: 0 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #9d9d9d;
    text-align: center;
    cursor: pointer;
}
.check-item {
    height: 32px;
    line-height: 32px;
}
.passenger-info {
    margin-bottom: 16px;
    background-color: #fff;
    padding: 8px 0;
    padding-left: 5%;
    line-height: 30px;
    position: relative;
    /* border: 1px solid #eae7e7; */
}
.booked-ticket + .booked-ticket {
    margin-top: 20px;
}
.booked-flight-rule {
    width: 15%;
}
.booked-flight-rule span {
    width: 80px;
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    color: #e34c4c;
    text-align: center;
    background-color: #fff;
    border: 1px solid #e34c4c;
    border-radius: 2px;
    transition: all, 0.68s;
    display: inline-block;
    margin: 0;
    padding: 0;
    height: 25px;
}
.booked-flight-cabin {
    width: 15%;
}
.booked-flight-cabin span:first-child {
    margin-right: 8px;
}
.booked-flight-arr {
    width: 20%;
}
.booked-flight-arr p:first-child {
    margin-top: 10px;
    height: 25px;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
}
.booked-flight-arr p:nth-child(2) {
    height: 20px;
    line-height: 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.booked-flight-arr p:last-child {
    height: 17px;
    font-size: 12px;
    line-height: 17px;
}
.booked-flight-duration p:first-child {
    margin-top: 23px;
    height: 17px;
    line-height: 17px;
}
.booked-flight-duration p:nth-child(2) {
    line-height: 15px;
}
.booked-flight-duration p:last-child {
    height: 17px;
    line-height: 17px;
}
.booked-flight-duration {
    width: 15%;
    padding: 0 10px;
    font-size: 12px;
    text-align: center;
}
.booked-flight-dep {
    text-align: right;
    width: 20%;
}
.booked-flight-dep p:first-child {
    margin-top: 10px;
    height: 25px;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
}
.booked-flight-dep p:nth-child(2) {
    height: 20px;
    line-height: 20px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
}
.booked-flight-dep p:last-child {
    height: 17px;
    font-size: 12px;
    line-height: 17px;
}
.qucheng {
    margin-left: 4px;
    color: #fff;
}
.booked-ticket-panel > div {
    line-height: 85px;
}
.booked-flight-type {
    color: #fff;
    background-color: #e34c4c;
    border: 1px solid #d31a1a;
    width: 15%;
    text-align: center;
}
.booked-flight-type p:first-child {
    margin-top: 10px;
    height: 20px;
    line-height: 20px;
}
.booked-flight-type p:last-child span {
    font-size: 16px;
    font-weight: 700;
}
.booked-flight-type p:last-child {
    margin-top: 10px;
    height: 22px;
    line-height: 22px;
}
.booked-ticket-panel {
    margin-bottom: 16px;
    background-color: #fff;
    border: 1px solid #eae7e7;
    display: flex;
    height: 85px;
}
.booked-ticket-body {
    // padding: 0 18px;
    font-size: 14px;
}
.passenger {
    // margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.info-panel {
    width: 100%;
    position: relative;
}
.fee-panel {
    width: 428px;
}
.booked-ticket {
    background: #f8f8f8;
    border: 1px solid #ededed;
    border-radius: 2px;
}
.booked-title {
    height: 42px;
    box-sizing: border-box;
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title {
    font-weight: 700;
    color: #4d4e4e;
    font-size: 16px;
}
.research {
    padding: 0;
    width: 90px;
    height: 26px;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
    color: #fff;
    text-align: center;
    background-color: #d70039;
    border-color: #ff0505;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.booked-ticket {
    background: #ffffff;
    border: 1px solid #ededed;
    border-radius: 10px;
}
.item-title {
    color: #929a9f;
    font-size: 14px;
    width: 70px;
    display: inline-block;
}
.item-index {
    color: #929a9f;
    font-size: 16px;
    font-style: italic;
    margin: 0 10px;
    display: inline-block;
}
.item-value {
    color: #515457;
    font-size: 14px;
    display: inline-block;
    margin-right: 10px;
}
.order-price {
    font-size: 24px;
    color: #d70039;
    margin: 0 10px;
}
.order-drop {
    width: 12px;
    height: 10px;
    margin-left: 10px;
}
.detail-content {
    position: absolute;
    width: 250px;
    /* height: 200px; */
    border-radius: 5px;
    border: 1px solid #ededed;
    background: #ffffff;
    z-index: 10000;
    padding: 10px;
}
.footer {
    text-align: right;
    font-size: 24px !important;
    border-top: 1px solid #ededed;
    color: #d70039;
}
.book-panel {
    width: 100px;
    position: absolute;
    display: flex;
    right: 10px;
    div {
        width: 50px;
        background-color: #d70039 !important;
        color: #ffffff;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        margin-left: 10px;
    }
}
.order-pass-item {
    display: flex;
    align-items: center;
    position: relative;
    width: 530px;
    flex-wrap: wrap;
    .ticketStatus {
        display: inline;
        padding: 3px;
        margin-left: 10px;
        text-align: center;
        color: #da4046;
        border-radius: 5px;
        border: 1px solid #da4046;
    }
    & > div {
        width: 260px;
    }
    &-check {
        position: absolute;
        right: 20px;
    }
}
</style>
